import Vue from 'vue';
import Vuex from 'vuex';
import VueTelInput from 'vue-tel-input';
import axios from 'axios';
import imgCompress from '../utiles/imageCompress';

Vue.use(Vuex);
Vue.use(VueTelInput);

export default new Vuex.Store({
  state: {
    date: null,
    step: 1,
    totalSteps: 5,
    activity_days: [], //obj arr
    activity_orders: [], //obj arr
    form_setting: {
      required_fields: {
        phone: true,
        profile_img: true,
        email: true,
        english_name: true,
        year_of_birth: true,
        gender: false,
      },
    },
    //registration form
    form: {
      fName_heb: null,
      lName_heb: null,
      fName_en: null,
      lName_en: null,
      phone: null,
      formated_phone: null,
      email: null,
      customer_zid: null,
      employee_zid: null,
      year_of_birth: null,
      id: null,
      passport: null,
      activity: null,
      order: null,
      profileImg: null,
      gender: null,
      chev_nationality: null,
      chev_dept: null,
      chev_site: null,
      chev_trainee: false,
      max_trainee: 0,
      registered_trainees: 0,
      reserved_trainees: 0,
      registration_open: true,
    },
    dialog: {
      title: '',
      body: '',
      show: false,
    },
    years: [],
    image_preview_url: null,
  },

  getters: {
    activity_days: (state) => {
      return state.activity_days;
    },
    activity_orders: (state) => {
      return state.activity_orders;
    },
    dialog: (state) => {
      return state.dialog;
    },
    step: (state) => {
      return state.step;
    },
    totalSteps: (state) => {
      return state.totalSteps;
    },
    requiredFields: (state) => {
      return state.form_setting;
    },
    date: (state) => {
      return state.date;
    },
    years: (state) => {
      return state.years;
    },
    profile_image: (state) => {
      console.log('get profile image called');
      return state.form.profileImg;
    },
    image_preview_url: (state) => {
      return state.image_preview_url;
    },
  },
  mutations: {
    SET_DATE(state, date) {
      state.date = date;
    },
    SET_STEP(state, step) {
      state.step = step;
    },
    SET_ACTIVITY_DAYS(state, activity_days) {
      state.activity_days = activity_days;
      // let id_req = activity_days[0]["activity.id_required"];
    },
    SET_ACTIVITY_ORDERS(state, activity_orders) {
      state.activity_orders = activity_orders;
    },
    SET_ACTIVITY(state, payload) {
      state.form.activity = payload;
      // console.log(payload["activity.chev_link"]);
      // if(payload["activity.chev_link"] == "true")
      // {
      //     state.form.chev_trainee = true;
      // }
    },
    SET_ORDER(state, payload) {
      state.form.order = payload;
      if (payload && payload.Is_Chev == 'true') {
        state.form.chev_trainee = true;
      } else {
        state.form.chev_trainee = false;
      }
    },
    SET_FORM(state, payload) {
      state.form.fName_heb = payload.fName_heb;
      state.form.fName_en = payload.fName_en;
      state.form.lName_heb = payload.lName_heb;
      state.form.lName_en = payload.lName_en;
    },
    SET_PHONE(state, payload) {
      let formatedPhone = helpers.formatPhone(payload);
      state.form.phone = formatedPhone;
    },
    SET_DIALOG(state, payload) {
      state.dialog.title = payload.title;
      state.dialog.body = payload.body;
      state.dialog.show = payload.show;
    },
    SET_CUSTOMER(state, payload) {
      if (state.form.email == null) {
        state.form.email = payload.email;
      }
      if (state.form.phone == null) {
        state.form.phone = payload.phone;
      }
      if (state.form.fName_heb == null) {
        state.form.fName_heb = payload.fName_heb;
      }
      if (state.form.fName_en == null) {
        state.form.fName_en = payload.fName_en;
      }
      if (state.form.lName_heb == null) {
        state.form.lName_heb = payload.lName_heb;
      }
      if (state.form.lName_en == null) {
        state.form.lName_en = payload.lName_en;
      }
      if (state.form.gender == null) {
        state.form.gender = payload.gender;
      }
      if (state.form.year_of_birth == null) {
        //find year_of_birth value on years array
        if (this.getters.years.includes(payload.year_of_birth)) {
          let index = this.getters.years.indexOf(payload.year_of_birth);
          let year = this.getters.years[index];
          state.form.year_of_birth = year;
        }
      }
      state.form.customer_zid = payload.id;
    },
    SET_EMPLOYEE(state, payload) {
      if (state.form.email == null) {
        state.form.email = payload.email;
      }
      if (state.form.phone == null) {
        state.form.phone = payload.phone;
      }
      if (state.form.fName_heb == null) {
        state.form.fName_heb = payload.fName_heb;
      }
      if (state.form.fName_en == null) {
        state.form.fName_en = payload.fName_en;
      }
      if (state.form.lName_heb == null) {
        state.form.lName_heb = payload.lName_heb;
      }
      if (state.form.lName_en == null) {
        state.form.lName_en = payload.lName_en;
      }
      if (state.form.gender == null) {
        state.form.gender = payload.gender;
      }
      if (state.form.year_of_birth == null) {
        //find year_of_birth value on years array
        if (this.getters.years.includes(payload.year_of_birth)) {
          let index = this.getters.years.indexOf(payload.year_of_birth);
          let year = this.getters.years[index];
          state.form.year_of_birth = year;
        }
      }
      state.form.employee_zid = payload.id;
    },
    SET_REQUIRED_FIELD(state, payload) {
      //JSON.parse() for convert string to bool
      state.form_setting.required_fields.phone = JSON.parse(payload.phone);
      state.form_setting.required_fields.profile_img = JSON.parse(
        payload.profile_img
      );
      state.form_setting.required_fields.email = JSON.parse(payload.email);
      state.form_setting.required_fields.english_name = JSON.parse(
        payload.english_name
      );
      state.form_setting.required_fields.year_of_birth = JSON.parse(
        payload.year_of_birth
      );
      state.form_setting.required_fields.gender = JSON.parse(payload.gender);
    },
    SET_YEARS(state, payload) {
      state.years = payload;
    },
    async SET_PROFILE_IMAGE(state, payload) {
      const compressed = await imgCompress(payload);
      console.log(compressed);
      state.form.profileImg = compressed;

      //create preview url
      console.log('SET_PROFILE_IMAGE: ' + payload);

      if (payload) {
        let previewUrl = URL.createObjectURL(payload);
        state.image_preview_url = previewUrl;
      } else {
        state.image_preview_url = null;
      }
    },
  },
  actions: {
    //set activity
    setActivity({ commit }, payload) {
      commit('SET_ACTIVITY', payload);
    },
    //set order
    setOrder({ commit }, payload) {
      commit('SET_ORDER', payload);
    },
    //set form
    setForm({ commit }, payload) {
      commit('SET_FORM', payload);
    },
    //set form
    setPhone({ commit }, payload) {
      commit('SET_PHONE', payload);
    },
    //set customer
    setCustomer({ commit }, payload) {
      commit('SET_CUSTOMER', payload);
    },
    //set dialog
    setDialog({ commit }, payload) {
      commit('SET_DIALOG', payload);
    },
    setStep({ commit }, payload) {
      commit('SET_STEP', payload);
    },
    setDate({ commit }, payload) {
      commit('SET_DATE', payload);
    },

    setRequiredFields({ commit }, payload) {
      commit('SET_REQUIRED_FIELD', payload);
    },
    setYears({ commit }, payload) {
      commit('SET_YEARS', payload);
    },
    setProfileImage({ commit }, payload) {
      console.log(payload);
      console.log(commit);
      commit('SET_PROFILE_IMAGE', payload);
    },
    /*
        GET
        */
    //get activityDays
    async getActivityDays({ commit }, payload) {
      // console.log(`get activity ${payload.get('activity')}`);
      let params = `date=${payload.get('date')}`;
      if (payload.get('activity') != null) {
        params = `activity=${payload.get('activity')}`;
      }
      try {
        console.log(`params: ${params}`);
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + `/activity_days?${params}`
        );
        // Update the registered trainees count data
        const maxTrainees = +(this.state.form.max_trainee =
          response?.data[0]['activity.max_trainees']);
        const registeredTrainees = +(this.state.form.max_trainee =
          response?.data[0]['activity.registered_trainees_count']);
        const reservedTrainees = +(this.state.form.max_trainee =
          response?.data[0]['activity.reserved_trainees_count']);

        this.state.form.max_trainee = maxTrainees;
        this.state.form.registered_trainees = registeredTrainees;
        this.state.form.reserved_trainees = reservedTrainees;

        if (registeredTrainees >= maxTrainees) {
          this.state.form.registration_open = false;
        }

        console.log(this.state.form);
        commit('SET_ACTIVITY_DAYS', response.data);
      } catch (error) {
        commit('SET_ACTIVITY_DAYS', null);
      }
    },

    //get activityOrders
    async getActivityOrders({ commit }, id) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + '/activity_orders?activity=' + id
        );
        commit('SET_ACTIVITY_ORDERS', response.data);
      } catch (error) {
        commit('SET_ACTIVITY_ORDERS', null);
      }
    },

    async fetchCustomer({ commit }) {
      let endpoint;
      if (this.state.form.id) {
        endpoint = 'id=' + this.state.form.id;
      } else if (this.state.form.passport) {
        endpoint = 'passport=' + this.state.form.passport;
      }
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + '/customer?' + endpoint
        );
        commit('SET_CUSTOMER', response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchEmployee({ commit }) {
      let endpoint;
      if (this.state.form.id) {
        endpoint = 'id=' + this.state.form.id;
      } else if (this.state.form.passport) {
        endpoint = 'passport=' + this.state.form.passport;
      }
      try {
        const response = await axios.get(
          process.env.VUE_APP_BASE_URL + '/employee?' + endpoint
        );
        commit('SET_EMPLOYEE', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    /*
        POST
        */
    // post customer
    // async postCustomer(data)
    // {
    //     console.log("postCustomer")
    //     try {
    //         const response = await axios.post(process.env.VUE_APP_BASE_URL +'/customer',data);
    //         console.log(response);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
  },
});

let helpers = {
  formatPhone(phone) {
    phone = phone.replace(/\s+/g, '');
    return phone;
  },
};
